import RegisterationForm from "../components/RegisterationForm";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="register h-100">
      <div className="registeration-form h-100">
        {isLoggedIn && navigate("/")}
        {!isLoggedIn && <RegisterationForm />}
      </div>
    </div>
  );
};

export default Register;
