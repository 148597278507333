import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import useSubmissionOnEnter from "../hooks/useSubmissionOnEnter";
import GlobalContext from "../context/GlobalContext";

const RegisterationForm = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [account] = useState("");
  const [address] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [emptyFields, setEmptyFields] = useState([]);
  const navigate = useNavigate();

  useSubmissionOnEnter("submit");
  const BACKEND_URL = useContext(GlobalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    const res = await fetch(`${BACKEND_URL}/api`, {
      method: "POST",
      body: JSON.stringify({
        username,
        email,
        password,
        account,
        address,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    if (!res.ok) {
      setError(json.message);
      setEmptyFields(json.emptyFields);
    }

    if (res.ok) {
      setError(null);
      setEmptyFields([]);
      setUsername("");
      setEmail("");
      setPassword("");

      setMessage(json.message);
    }
  };

  return (
    <Container className={"h-100"}>
      <Row className={"h-100"}>
        <Col
          className={"align-self-center offset-xl-3 offset-lg-2"}
          md={12}
          lg={8}
          xl={6}
        >
          {error && (
            <Alert key={"danger"} color={"danger"} toggle={() => setError("")}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert
              key={"success"}
              color={"success"}
              toggle={() => setMessage("")}
            >
              {message}
            </Alert>
          )}
          <Card>
            <CardBody>
              <Form>
                <h3>Register</h3>
                <FormGroup className="mb-3" controlId="username">
                  <Label>Username</Label>
                  <Input
                    type="text"
                    placeholder={"Username"}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className={
                      emptyFields !== undefined &&
                      emptyFields.includes("username")
                        ? "error"
                        : ""
                    }
                  />
                </FormGroup>

                <FormGroup className="mb-3" controlId="email">
                  <Label>Email</Label>
                  <Input
                    type="email"
                    placeholder={"Email"}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className={
                      emptyFields !== undefined && emptyFields.includes("email")
                        ? "error"
                        : ""
                    }
                  />
                </FormGroup>

                <FormGroup className="mb-3" controlId="password">
                  <Label>Password</Label>
                  <Input
                    type="password"
                    placeholder={"Password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className={
                      emptyFields !== undefined &&
                      emptyFields.includes("password")
                        ? "error"
                        : ""
                    }
                  />
                </FormGroup>

                <FormGroup className={"text-end"}>
                  <ButtonGroup>
                    <Button
                      id={"submit"}
                      color="success"
                      onClick={handleSubmit}
                    >
                      Register
                    </Button>
                  </ButtonGroup>
                </FormGroup>

                <FormGroup className={"text-end"}>
                  <ButtonGroup>
                    <Button
                      size={"sm"}
                      color="secondary"
                      onClick={() => navigate("/")}
                    >
                      Login
                    </Button>

                    <Button
                      size={"sm"}
                      color="secondary"
                      onClick={() => navigate("/recover-password")}
                    >
                      Recover Password
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterationForm;
