import { useState } from "react";
import icon from "../icons/icon";
import { NavItem, NavLink } from "reactstrap";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <aside className={`sidebar ${isOpen ? "open" : "close"}`}>
      <ul className="menu">
        <li className="nav-item">
          <div
            className="nav-content"
            onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
          >
            <span className="icon-medium">{icon("menu")}</span>
          </div>
        </li>
        <NavItem>
          <NavLink href={"/admin/dashboard"}>
            <span className="nav-content">
              <span className="icon">
                <span className="heartbeat">&nbsp;</span>
                {icon("dashboard")}
              </span>
              <span className="text">Dashboard</span>
            </span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink href={"/admin/settings"}>
            <span className="nav-content">
              <span className="icon">{icon("settings")}</span>
              <span className="text">Settings</span>
            </span>
          </NavLink>
        </NavItem>
      </ul>
    </aside>
  );
};

export default Sidebar;
