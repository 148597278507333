import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import icon from "../icons/icon";
import invalidateToken from "../../../helpers/invalidateToken";
import GlobalContext from "../../../context/GlobalContext";

const Topbar = () => {
  const [user, setUser] = useState();
  const [alertsDropdownOpen, setAlertsDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const BACKEND_URL = useContext(GlobalContext);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(
      `${BACKEND_URL}/api/users/token/${localStorage.getItem("jwt")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      },
    );

    const json = await res.json();
    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
    }

    if (res.ok) {
      setUser(json);
    }
  };

  return (
    <header className="topbar">
      <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <div className="navbar-collapse navbar__container">
          <Link className="home-link" to="/">
            <div className="navbar-nav brand mr-auto">
              <span className="logo icon-big">{icon("office")}</span>
              <span className="name">Office</span>
            </div>
          </Link>
          <div className="text-center mx-auto">&nbsp;</div>
          <ul className="navbar-nav my-lg-0 align-items-center">
            <Dropdown
              isOpen={alertsDropdownOpen}
              toggle={() => setAlertsDropdownOpen(!alertsDropdownOpen)}
            >
              <DropdownToggle
                className={"transparent-btn d-flex align-items-center"}
                caret
              >
                <span className="icon">
                  <span className="heartbeat">&nbsp;</span>
                  {icon("notification")}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Notifications Center</DropdownItem>
                <DropdownItem disabled={true}>
                  <div className="mail-contnet">
                    <h5>Welcome</h5>{" "}
                    <span className="mail-desc">Welcome to the office</span>
                    <span className="time">10:30 AM</span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={userDropdownOpen}
              toggle={() => setUserDropdownOpen(!userDropdownOpen)}
            >
              <DropdownToggle
                className={"transparent-btn d-flex align-items-center gap-1"}
                caret
              >
                <span className="name">
                  {user && user.firstName + " " + user.lastName}
                </span>
                <span className="icon">{icon("user")}</span>
              </DropdownToggle>
              <DropdownMenu>
                <Link
                  className={"text-decoration-none"}
                  to={"/admin/profile/settings"}
                >
                  <DropdownItem>Profile Settings</DropdownItem>
                </Link>
                <DropdownItem divider />
                <Link className={"text-decoration-none"} to={"/logout"}>
                  <DropdownItem>Logout</DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
