import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import RecoverPassword from "./pages/RecoverPassword";
import Invoice from "./pages/admin/pages/Invoice";
import Invoices from "./pages/admin/pages/Invoices";
import Dashboard from "./pages/admin/pages/Dashboard";
import Contracts from "./pages/admin/pages/Contracts";
import Settings from "./pages/admin/pages/Settings";
import ProfileSettings from "./pages/admin/pages/ProfileSettings";
import ProtectedRoutes from "./helpers/ProtectedRoutes";
import Companies from "./pages/admin/pages/Companies";
import GlobalContext from "./context/GlobalContext";

function App() {
  // const BACKEND_URL = 'http://localhost:5003';
  const BACKEND_URL = "https://back.digital-dynamite.co.uk";

  return (
    <GlobalContext.Provider value={BACKEND_URL}>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/register"} element={<Register />} />
          <Route path={"/recover-password"} element={<RecoverPassword />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route path={"/invoices/:number/:secret"} element={<Invoice />} />
          <Route element={<ProtectedRoutes />}>
            <Route path={"/admin/dashboard"} element={<Dashboard />} />
            <Route path={"/admin/invoices"} element={<Invoices />} />
            <Route path={"/admin/companies"} element={<Companies />} />
            <Route path={"/admin/contracts"} element={<Contracts />} />
            <Route path={"/admin/settings"} element={<Settings />} />
            <Route
              path={"/admin/profile/settings"}
              element={<ProfileSettings />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
