import { useState, useEffect } from "react";
import PreLoader from "../../PreLoader";
import Topbar from "./sections/Topbar";
import Sidebar from "./sections/Sidebar";
import Footer from "./sections/Footer";

const Globals = (props) => {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  }, []);

  return (
    <>
      {isLoading && <PreLoader label={process.env.APP_NAME} />}
      <Sidebar />
      <Topbar />
      {children}
      <Footer />
    </>
  );
};
export default Globals;
