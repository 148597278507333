import { Container, Row, Col, Table } from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import "../../../styles/main.scss";
import invalidateToken from "../../../helpers/invalidateToken";
import GlobalContext from "../../../context/GlobalContext";
import PreLoader from "../../../PreLoader";

const Invoice = () => {
  const [invoice, setInvoice] = useState([]);
  const [user, setUser] = useState([]);
  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { number, secret } = useParams();
  const BACKEND_URL = useContext(GlobalContext);

  useEffect(() => {
    getInvoice(number, secret);
  }, [number, secret]);

  const getInvoice = async (number, secret) => {
    const res = await fetch(`${BACKEND_URL}/api/invoices/${number}/${secret}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    if (!res.ok) {
      setInvoice("");
    }

    if (res.ok) {
      setInvoice(json.invoice);
      getUser(json.invoice.to);
      getComapny(json.invoice.company);
    }
  };

  const getUser = async (username) => {
    const res = await fetch(`${BACKEND_URL}/api/users/public/${username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
    }

    if (res.ok) {
      setUser(json);
    }
  };

  const getComapny = async (id) => {
    const res = await fetch(`${BACKEND_URL}/api/companies/public/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
    }

    if (res.ok) {
      setCompany(json);
    }

    setIsLoading(false);
  };

  return (
    <Container className={"pt-4"} style={{ maxWidth: "700px" }}>
      {isLoading && <PreLoader label={process.env.APP_NAME} />}
      <Row>
        <Col xs={7}>
          {company.logo && (
            <img src={company.logo} width={175} alt="Company Logo" />
          )}
          {!company.logo && <h2 className="page-title">{company.name}</h2>}
          <p className="small pt-3 mb-0">
            <a href={`mailto:${company.email}`}>{company.email}</a>
            <br />
            <a href={company.domain}>{company.domain}</a>
          </p>
        </Col>
        <Col className={"small mb-0"} xs={5}>
          <p className="small">
            {company.address &&
              company.address.split("\n").map((str) => (
                <>
                  {str} <br />
                </>
              ))}
          </p>
          <p className={"mt-3"}>
            VAT #: {company.vat} <br />
            Company #: {company.inc}
          </p>
        </Col>
        <hr className="my-3" />
      </Row>
      <Row className={"align-items-start"}>
        <Col xs={7}>
          <h5>Recipient:</h5>
          <p className="small">Name: {user.firstName + " " + user.lastName}</p>
          {invoice.toRoleLabel && (
            <p className="small">Title: {invoice.toRoleLabel}</p>
          )}
          <p className={"small"}>
            Address:{" "}
            {user.address &&
              user.address.split("\n").map((str) => (
                <>
                  {str} <br />
                </>
              ))}
          </p>
          <p className={"small"}>
            IBAN: {user.account}
            <br />
            SWIFTBIC: {user.swift}
          </p>
        </Col>
        <Col xs={5}>
          <h5 className={""}>Payment #: {invoice.number}</h5>
          {invoice.paymentMonth && (
            <p className={"small"}>Payment for: {invoice.paymentMonth}</p>
          )}
          <p className={"small"}>
            Issued on: {new Date(invoice.date).toLocaleDateString("en-GB")}
          </p>
        </Col>
      </Row>
      <Row className={"pt-5"}>
        <Col>
          <Table>
            <thead>
              <tr>
                <th className={"text-center"} style={{ minWidth: "70px" }}>
                  Task #
                </th>
                <th>Task</th>
                <th width={400}>Detail</th>
                <th className={"text-end"}>Log</th>
              </tr>
            </thead>
            <tbody>
              {invoice.items &&
                invoice.items.length &&
                invoice.items.map((item) => {
                  return (
                    <tr>
                      <td className={"text-center"}>
                        <p className="small">{item.taskNumber}</p>
                      </td>
                      <td>
                        <p className="small">{item.task}</p>
                      </td>
                      <td>
                        <p className="small">{item.detail}</p>
                      </td>
                      <td className={"text-end"}>
                        <p className="small">{item.log}</p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Table>
            <thead></thead>
            <tbody>
              <tr>
                <td colSpan={2} width={400}></td>
                <td>
                  <p className="small">Logged Hours</p>
                </td>
                <td className={"text-end"}>
                  <p className="small">
                    {invoice.items &&
                      invoice.items.length &&
                      invoice.items.reduce((r, cur) => {
                        return r + parseInt(cur.log);
                      }, 0)}
                  </p>
                </td>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <td>
                  <p className="small">Basic Pay</p>
                </td>
                <td className={"text-end"}>
                  <p className="small" style={{ minWidth: "48px" }}>
                    £ {invoice.total}
                  </p>
                </td>
              </tr>
              {invoice.bonus !== undefined && invoice.bonus !== 0 && (
                <tr>
                  <td colSpan={2}></td>
                  <td>
                    <p className="small">Bonus</p>
                  </td>
                  <td className={"text-end"}>
                    <p className="small">£ {invoice.bonus}</p>
                  </td>
                </tr>
              )}
              <tr>
                <th colSpan={2}></th>
                <th>
                  <p className="small">Net Total</p>
                </th>
                <th className={"text-end"}>
                  <p className="small" style={{ minWidth: "48px" }}>
                    £ {invoice.bonus + invoice.total}
                  </p>
                </th>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td>
                  <p className="small">Barclays + delivery charge</p>
                </td>
                <td className={"text-end"}>
                  <p className="small">£ {invoice.charges}</p>
                </td>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <th>
                  <p className="small">Gross Total</p>
                </th>
                <th className={"text-end"}>
                  <p className="small" style={{ minWidth: "48px" }}>
                    £ {invoice.bonus + invoice.total + invoice.charges}
                  </p>
                </th>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className={"pt-5 pb-2"}>
        <Col className={"d-flex justify-content-center"}>
          <QRCode
            size={75}
            value={`${company.domain}/invoices/${invoice.number}/${invoice.secret}`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className={"small text-center"}>
            This self-generated payment confirmation, issued by the{" "}
            {company.name}, does not require a signature. You can verify it
            online at{" "}
            <a
              href={`${company.domain}/invoices/${invoice.number}/${invoice.secret}`}
            >{`${company.domain}`}</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Invoice;
