import { Container, Row, Col } from "reactstrap";
import icon from "../icons/icon";
import Globals from "../Globals";
import "../../../styles/main.scss";

const Contracts = () => {
  return (
    <Globals>
      <Container className={"pt-4"}>
        <Row>
          <Col lg={8} md={12}>
            <h1 className="page-title">Contracts</h1>
          </Col>
          <Col lg={4} md={12}>
            <div className="search-group">
              <input
                type="search"
                className="search"
                placeholder="Search for the modules"
                width="300px"
              />
              <span className="icon">{icon("search")}</span>
            </div>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row>
          <Col lg={8} md={12}>
            <Row></Row>
          </Col>
          <Col className={"pt-4 pt-lg-0"} lg={4} md={12}>
            <div className="faq-section">
              <h3 className="faq-title">Frequently searched</h3>
              <ul className="faqs">
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my brand performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my email campaign performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top performing pages?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top traffic channels?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How much traffic is my website getting?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How can I generate more traffic?
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Globals>
  );
};

export default Contracts;
