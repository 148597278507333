const icon = (name, stroke = "#000") => {
  switch (name) {
    case "dashboard":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 24 25.01"
        >
          <title>{"Dashboard"}</title>
          <path
            className="cls-1"
            d="M12 1.01v2M4.22 4.24l1.42 1.41M1 12.01h2M21 12.01h2M18.36 5.65l1.42-1.41"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
          />
          <path
            className="cls-1"
            d="M297.75 156h15.07a11 11 0 1 0-15.55-.48q.24.28.48.48Z"
            transform="translate(-293.29 -136.01)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
          />
          <circle
            className="cls-1"
            cx={12}
            cy={12.01}
            r={2}
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
          />
          <path
            className="cls-1"
            d="m16 8.01-2 2M8 24.01h8"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
          />
        </svg>
      );

    case "menu":
      return (
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>{"Menu"}</title>
          <path
            className="cls-1"
            d="M2 3h20M2 12h14M2 21h20"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeWidth="2px"
          />
          <path
            style={{
              fill: "none",
            }}
            d="M0 0h24v24H0z"
          />
        </svg>
      );

    case "anylatics":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M23 23H3a2 2 0 0 1-2-2V1"
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
          <path
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
            d="M5 13h3v6H5zM11 3h3v16h-3zM17 9h3v10h-3z"
          />
        </svg>
      );

    case "wrench":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22.94 22.89"
        >
          <title>{"Wrench"}</title>
          <path
            d="m21.48 5.61-3.05 3.05a3 3 0 0 1-4.52-3.95l.25-.25h0l3.05-3a7 7 0 0 0-7.47 1.77 7.11 7.11 0 0 0-1.45 7.1l-6.44 6.45A3 3 0 0 0 6.16 21h0l6.43-6.43a7 7 0 0 0 8.94-8.94Z"
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
        </svg>
      );

    case "settings":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24.17 24.08"
        >
          <title>{"Settings"}</title>
          <path
            style={{
              fill: "none",
            }}
            d="M0 0h24v24H0z"
          />
          <path
            d="M15.5 12.12a3.38 3.38 0 1 1-3.39-3.37h0a3.37 3.37 0 0 1 3.39 3.37Z"
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
          <path
            d="M19.53 15.12a1.66 1.66 0 0 0 .33 1.82l.06.06a2 2 0 1 1-2.83 2.83l-.09-.06a1.66 1.66 0 0 0-2.35 0h0a1.61 1.61 0 0 0-.47 1.14v.17a2 2 0 0 1-4 0V21a1.74 1.74 0 0 0-1.82-1.64 1.82 1.82 0 0 0-1.08.46l-.06.06A2 2 0 0 1 4.42 17h.06a1.66 1.66 0 0 0 0-2.35h0a1.61 1.61 0 0 0-1.14-.47h-.17a2 2 0 0 1 0-4h.09A1.74 1.74 0 0 0 4.9 8.34a1.82 1.82 0 0 0-.46-1.08l-.06-.06a2 2 0 0 1 2.83-2.83l.06.06a1.66 1.66 0 0 0 1.82.33h.08a1.65 1.65 0 0 0 1-1.51v-.17a2 2 0 0 1 4 0v.09a1.66 1.66 0 0 0 1.67 1.65h0A1.63 1.63 0 0 0 17 4.35l.06-.06a2 2 0 1 1 2.83 2.83h0l-.06.06A1.66 1.66 0 0 0 19.49 9v.08a1.64 1.64 0 0 0 1.51 1h.17a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.55 1.04Z"
            style={{
              fill: "none",
              stroke: stroke,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
        </svg>
      );

    case "check":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16.5 15.5"
        >
          <title>{"Check"}</title>
          <g data-name="Group 451">
            <circle
              data-name="Ellipse 114"
              cx={7.75}
              cy={7.75}
              r={7}
              style={{
                fill: "none",
                stroke: stroke,
                strokeWidth: "1.5px",
              }}
            />
            <path
              data-name="Path 823"
              d="m4.62 6.71 3.05 3 8.08-8.08"
              style={{
                fill: "none",
                stroke: stroke,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "1.5px",
              }}
            />
          </g>
        </svg>
      );

    case "performance":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 58 58"
        >
          <title>{"Performance"}</title>
          <g data-name="Group 466">
            <path
              data-name="Path 831"
              d="M55.27 23h-2a24.83 24.83 0 0 0-2.87-6.92l1.42-1.41a2.75 2.75 0 0 0 0-3.87l-4.63-4.62a2.74 2.74 0 0 0-3.86 0L41.92 7.6A24.83 24.83 0 0 0 35 4.73v-2A2.73 2.73 0 0 0 32.27 0h-6.54A2.73 2.73 0 0 0 23 2.73v2a24.83 24.83 0 0 0-6.92 2.87l-1.41-1.42a2.75 2.75 0 0 0-3.87 0L6.18 10.8a2.75 2.75 0 0 0 0 3.87l1.42 1.41A24.54 24.54 0 0 0 4.73 23h-2A2.73 2.73 0 0 0 0 25.73v6.54A2.73 2.73 0 0 0 2.73 35h2a24.83 24.83 0 0 0 2.87 6.92l-1.42 1.41a2.74 2.74 0 0 0 0 3.86l4.62 4.63a2.75 2.75 0 0 0 3.87 0l1.41-1.42A24.83 24.83 0 0 0 23 53.27v2A2.73 2.73 0 0 0 25.73 58h6.54A2.73 2.73 0 0 0 35 55.27v-2a24.83 24.83 0 0 0 6.92-2.87l1.41 1.42a2.74 2.74 0 0 0 3.86 0l4.63-4.63a2.74 2.74 0 0 0 0-3.86l-1.42-1.41A24.83 24.83 0 0 0 53.27 35h2A2.73 2.73 0 0 0 58 32.27v-6.54A2.73 2.73 0 0 0 55.27 23Zm.73 9.27a.73.73 0 0 1-.73.73h-2.79a1 1 0 0 0-1 .79 22.91 22.91 0 0 1-3.2 7.73 1 1 0 0 0 .13 1.25l2 2a.74.74 0 0 1 0 1l-4.62 4.63a.74.74 0 0 1-1 0l-2-2a1 1 0 0 0-1.25-.13 22.74 22.74 0 0 1-7.74 3.23 1 1 0 0 0-.8 1v2.79a.73.73 0 0 1-.73.73h-6.53a.75.75 0 0 1-.74-.73v-2.81a1 1 0 0 0-.79-1 22.6 22.6 0 0 1-7.72-3.21 1 1 0 0 0-1.25.13l-2 2a.74.74 0 0 1-1 0L7.6 45.78a.74.74 0 0 1 0-1l2-2a1 1 0 0 0 .13-1.25 22.91 22.91 0 0 1-3.2-7.73 1 1 0 0 0-1-.79H2.74a.73.73 0 0 1-.74-.72v-6.56a.73.73 0 0 1 .73-.73h2.8a1 1 0 0 0 1-.79 22.78 22.78 0 0 1 3.2-7.72 1 1 0 0 0-.13-1.26l-2-2a.74.74 0 0 1 0-1l4.62-4.63a.74.74 0 0 1 1 0l2 2a1 1 0 0 0 1.25.13 22.74 22.74 0 0 1 7.74-3.23 1 1 0 0 0 .79-1V2.73a.75.75 0 0 1 .74-.73h6.53a.73.73 0 0 1 .73.73v2.8a1 1 0 0 0 .8 1 22.6 22.6 0 0 1 7.72 3.21 1 1 0 0 0 1.25-.13l2-2a.74.74 0 0 1 1 0l4.62 4.62a.74.74 0 0 1 0 1l-2 2a1 1 0 0 0-.13 1.26 22.78 22.78 0 0 1 3.2 7.72 1 1 0 0 0 1 .79h2.79a.73.73 0 0 1 .73.73Z"
              fill={stroke}
            />
            <path
              data-name="Path 832"
              d="M42.44 15.57a19 19 0 0 0-26.78 0 19 19 0 0 0 0 26.78 19 19 0 0 0 26.78 0 19 19 0 0 0 0-26.78ZM45 30h1a16.86 16.86 0 0 1-4.26 10.27l-.74-.66a1 1 0 0 0-1.65 1.13 1.07 1.07 0 0 0 .26.26l.66.67A16.83 16.83 0 0 1 30 46v-1a1 1 0 0 0-2 0v1a16.86 16.86 0 0 1-10.27-4.26l.66-.67A1 1 0 0 0 17 39.61l-.67.66A16.82 16.82 0 0 1 12.05 30H13a1 1 0 0 0 0-2h-.95a16.86 16.86 0 0 1 4.26-10.27l.67.66a1 1 0 1 0 1.61-1.19 1.26 1.26 0 0 0-.2-.2l-.66-.67A16.82 16.82 0 0 1 28 12.05V13a1 1 0 0 0 2 0v-.95a16.86 16.86 0 0 1 10.27 4.26l-.66.67a1 1 0 0 0 1.19 1.61 1.26 1.26 0 0 0 .2-.2l.67-.66A16.83 16.83 0 0 1 46 28h-1a1 1 0 0 0 0 2Z"
              fill={stroke}
            />
            <path
              data-name="Path 833"
              d="m37.3 18.75-7 5.44a5 5 0 1 0 3.49 6.15 5 5 0 0 0-.09-2.95l5-7.29a1 1 0 0 0-1.44-1.35Zm-5.53 8a1 1 0 0 0-.07 1 3 3 0 1 1-4-1.4A2.84 2.84 0 0 1 29 26a3.08 3.08 0 0 1 1.16.23 1 1 0 0 0 1-.14l2.28-1.77Z"
              fill={stroke}
            />
            <path
              data-name="Path 834"
              d="M33.18 18.83a1 1 0 0 0 1.22-.71 1 1 0 0 0-.46-1.12 13 13 0 0 0-13.61 2.33 1 1 0 0 0 1.32 1.5 11 11 0 0 1 11.51-2Z"
              fill={stroke}
            />
            <path
              data-name="Path 835"
              d="M24.88 22a1 1 0 0 0 .45-.11 8.13 8.13 0 0 1 5.15-.76 1 1 0 0 0 .41-2 10.11 10.11 0 0 0-6.42.94 1 1 0 0 0-.47 1.37 1 1 0 0 0 .88.56Z"
              fill={stroke}
            />
          </g>
        </svg>
      );

    case "customer":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52.52 58.03"
        >
          <title>{"Customer"}</title>
          <path
            d="M38.5 30a14 14 0 0 0-5 .94 16.88 16.88 0 0 0-5.68-9.06A10.5 10.5 0 0 1 47.5 27a1 1 0 1 0 2 0 12.52 12.52 0 0 0-8.34-11.77 7.48 7.48 0 1 0-8.34 0 12.48 12.48 0 0 0-6.65 5.54 17 17 0 0 0-4.3-2 10 10 0 1 0-9.75 0A17 17 0 0 0 0 35a1 1 0 0 0 2 0 15 15 0 0 1 29.65-3.2A14 14 0 1 0 38.5 30Zm-7-21a5.47 5.47 0 1 1 5.5 5.46A5.47 5.47 0 0 1 31.53 9ZM9 10a8 8 0 1 1 8 8 8 8 0 0 1-8-8Zm29.5 46a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z"
            fill={stroke}
          />
          <path
            d="M44.5 43h-5v-5a1 1 0 0 0-2 0v5h-5a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 0 0 0-2Z"
            fill={stroke}
          />
        </svg>
      );

    case "support":
      return (
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 60.84 62"
        >
          <title>{"Support"}</title>
          <path
            d="M59.84 41h-9a1 1 0 0 0-1 1v.92a16 16 0 0 0-9-2.92 19.21 19.21 0 0 0-10.31 3h-7.69a5 5 0 0 0-5 5 4.92 4.92 0 0 0 .47 2.1h-.11L6.29 44.46a4.35 4.35 0 1 0-3.87 7.8l13.93 6.61A10.7 10.7 0 0 0 21.13 60h28.71v1a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V42a1 1 0 0 0-1-1ZM21.13 58a8.65 8.65 0 0 1-3.9-.93L3.29 50.46a2.36 2.36 0 0 1 1.88-4.33 1.51 1.51 0 0 1 .22.11l11.93 5.61A10.71 10.71 0 0 0 22.13 53h10.71a1 1 0 0 0 0-2h-10a3 3 0 0 1 0-6h8a1 1 0 0 0 .6-.2 17.14 17.14 0 0 1 9.4-2.8 14.36 14.36 0 0 1 9 3.44V58Zm37.71 2h-7V43h7Z"
            fill={stroke}
          />
          <path
            d="M16.8 42.71a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.42l-9.88-9.88a1.32 1.32 0 0 1 0-1.87L35.49 2.39a1.33 1.33 0 0 1 1-.39l10.11.29A6.15 6.15 0 0 1 52.38 7H50.7a4 4 0 1 0 0 2h1.87l.27 9.39a1.34 1.34 0 0 1-.38 1L33.14 38.68a1 1 0 0 0 1.41 1.41l19.32-19.32a3.29 3.29 0 0 0 1-2.44L54.74 15h2.1a4 4 0 0 0 0-8h-2.42A8.18 8.18 0 0 0 46.63.29L36.52 0a3.31 3.31 0 0 0-2.45 1L6.92 28.13a3.33 3.33 0 0 0 0 4.7Zm31.46-33.3a2 2 0 0 1-2.83 0 2 2 0 1 1 2.65-3l.13.13a2.1 2.1 0 0 1 .31.41h-.73a1 1 0 0 0 0 2h.73a2.1 2.1 0 0 1-.31.41ZM58.84 11a2 2 0 0 1-2 2h-2.15l-.12-4h2.27a2 2 0 0 1 2 2Z"
            fill={stroke}
          />
        </svg>
      );

    case "marketing":
      return (
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 61 58"
        >
          <title>{"Marketing"}</title>
          <path
            d="M4,54h8a1,1,0,0,0,1-1V28a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1V53A1,1,0,0,0,4,54ZM5,29h6V52H5Z"
            fill={stroke}
          />
          <path
            d="M19,17a1,1,0,0,0-1,1V53a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V18a1,1,0,0,0-1-1Zm7,35H20V19h6Z"
            fill={stroke}
          />
          <path
            d="M34,28a1,1,0,0,0-1,1V53a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V29a1,1,0,0,0-1-1Zm7,24H35V30h6Z"
            fill={stroke}
          />
          <path
            d="M49,12a1,1,0,0,0-1,1V53a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V13a1,1,0,0,0-1-1Zm7,40H50V14h6Z"
            fill={stroke}
          />
          <path
            d="M8,23a4,4,0,0,0,4-3.91,3.72,3.72,0,0,0-.24-1.4L20.35,12a3.91,3.91,0,0,0,5.16.12l8.78,6.43A3.79,3.79,0,0,0,34,20a4,4,0,0,0,8,0,3.9,3.9,0,0,0-.63-2.13L51.09,7.49A3.87,3.87,0,0,0,53,8a4,4,0,1,0-4-4.06,3.94,3.94,0,0,0,.63,2.19L39.91,16.51a3.88,3.88,0,0,0-4.43.41l-8.77-6.44A3.94,3.94,0,0,0,27,9a4,4,0,1,0-8-.1,3.77,3.77,0,0,0,.24,1.41L10.65,16A4,4,0,1,0,8,23ZM53,2a2,2,0,1,1-2,2A2,2,0,0,1,53,2ZM38,18a2,2,0,1,1-2,2A2,2,0,0,1,38,18ZM23,7a2,2,0,1,1-2,2A2,2,0,0,1,23,7ZM8,17a2,2,0,1,1-2,2A2,2,0,0,1,8,17Z"
            fill={stroke}
          />
          <path d="M60,56H1a1,1,0,0,0,0,2H60a1,1,0,0,0,0-2Z" fill={stroke} />
        </svg>
      );

    case "search":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>{"Search"}</title>
          <g data-name="Group 14">
            <circle
              data-name="Ellipse 1"
              cx={11}
              cy={11}
              r={9}
              style={{
                fill: "none",
                stroke: stroke,
                strokeWidth: 2,
              }}
            />
            <path
              data-name="Line 5"
              style={{
                fill: "none",
                stroke: stroke,
                strokeLinecap: "round",
                strokeWidth: 2,
              }}
              d="m22 22-4-4"
            />
          </g>
          <path
            data-name="Rectangle 30"
            style={{
              fill: "none",
            }}
            d="M0 0h24v24H0z"
          />
        </svg>
      );

    case "notification":
      return (
        <svg
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52.01 57.32"
        >
          <title>{"Notification"}</title>
          <path
            d="M49 53H3a3 3 0 0 1-2.77-1.86 2.94 2.94 0 0 1 .65-3.25l2.69-2.69a5.33 5.33 0 0 0 1.57-3.79V24.17a20.86 20.86 0 0 1 41.72 0V41.4a5.33 5.33 0 0 0 1.57 3.79l2.69 2.69A3 3 0 0 1 49 53ZM26 5.31A18.88 18.88 0 0 0 7.14 24.17V41.4A7.35 7.35 0 0 1 5 46.6l-2.7 2.69a1 1 0 0 0-.21 1.07A1 1 0 0 0 3 51h46a1 1 0 0 0 .91-.61 1 1 0 0 0-.21-1.07L47 46.6a7.32 7.32 0 0 1-2.16-5.2V24.17A18.85 18.85 0 0 0 26 5.31Z"
            fill={stroke}
          />
          <path
            d="M24.6 5.39a1 1 0 0 1-.71-.29 3 3 0 1 1 4.27 0 1 1 0 1 1-1.43-1.39A1 1 0 0 0 27 3a1 1 0 1 0-1.69.7 1 1 0 0 1 0 1.41 1.09 1.09 0 0 1-.71.28ZM26 57.32a6 6 0 0 1-5.95-5.22 1 1 0 0 1 2-.26 4 4 0 0 0 7.94 0 1 1 0 0 1 2 .26A6 6 0 0 1 26 57.32Z"
            fill={stroke}
          />
        </svg>
      );

    case "user":
      return (
        <svg
          id="Capa_1"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52.38 52.39"
        >
          <title>{"User"}</title>
          <circle
            className="cls-1"
            cx={26.19}
            cy={13.33}
            r={9.3}
            stroke={stroke}
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="2px"
          />
          <path
            className="cls-1"
            d="M26.19 26.2A17.31 17.31 0 0 0 8.88 43.51v1a25.17 25.17 0 0 0 34.52 0v-1A17.31 17.31 0 0 0 26.19 26.2Z"
            stroke={stroke}
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="2px"
          />
          <circle
            className="cls-1"
            cx={26.19}
            cy={26.19}
            r={25.19}
            stroke={stroke}
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="2px"
          />
        </svg>
      );

    case "office":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 1586.81 1871.91"
        >
          <g data-name="Layer_1">
            <g data-name={452560848}>
              <path
                d="M1388.84 1043.75v774.66a32 32 0 0 1-32 32H102a32 32 0 0 1-32-32v-774.66a32 32 0 0 1 32-32h1254.84a32 32 0 0 1 32 32Zm-64 742.65v-710.65H134v710.65Z"
                data-name={452561448}
                style={{
                  fill: "#212121",
                  stroke: "#1d1d1b",
                  strokeMiterlimit: 10,
                  strokeWidth: 43,
                }}
              />
              <path
                d="m238.5 27.26 1265.34 465.42a94.07 94.07 0 0 1 55.71 120.53l-90.71 246.61a94.08 94.08 0 0 1-120.53 55.7L83 450.1a94.07 94.07 0 0 1-55.7-120.52L118 83a94.08 94.08 0 0 1 120.5-55.74Zm1243.25 525.49L216.4 87.33a30.06 30.06 0 0 0-38.4 17.73L87.33 351.67A30.05 30.05 0 0 0 105.06 390l1265.35 465.46a30.07 30.07 0 0 0 38.36-17.73l90.71-246.62a30 30 0 0 0-17.73-38.36Z"
                data-name={452560944}
                style={{
                  fill: "#212121",
                  stroke: "#1d1d1b",
                  strokeMiterlimit: 10,
                  strokeWidth: 43,
                }}
              />
              <path
                d="M570.08 396.71A32 32 0 1 0 548 456.77l441.8 162.51a32 32 0 0 0 22.1-60.06L570.08 396.71Z"
                data-name={452560800}
                style={{
                  fill: "#212121",
                  stroke: "#1d1d1b",
                  strokeMiterlimit: 10,
                  strokeWidth: 43,
                }}
              />
              <path
                d="M462.63 1273.55h533.52a32 32 0 0 1 32 32v251.07a32 32 0 0 1-32 32H462.63a32 32 0 0 1-32-32v-251.07a32 32 0 0 1 32-32Zm501.52 64H494.63v187.07h469.52v-187.07Z"
                data-name={452560728}
                style={{
                  fill: "#212121",
                  stroke: "#1d1d1b",
                  strokeMiterlimit: 10,
                  strokeWidth: 43,
                }}
              />
            </g>
          </g>
        </svg>
      );

    case "invoice":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 693.33 800"
        >
          <path d="M147.07 613.33h-.14a13.4 13.4 0 1 0 .14 0ZM227.06 613.33h-.13a13.41 13.41 0 1 0 .13 0ZM333.33 426.67H226.66a13.33 13.33 0 1 0 0 26.66h106.67a13.33 13.33 0 1 0 0-26.66ZM280 506.67h-53.34a13.33 13.33 0 1 0 0 26.66H280a13.33 13.33 0 0 0 0-26.66ZM506.66 466.67A26.69 26.69 0 0 1 480 440a13.34 13.34 0 0 0-26.67 0 53.27 53.27 0 0 0 40 51.44v1.89a13.34 13.34 0 1 0 26.67 0v-1.89a53.22 53.22 0 0 0-13.34-104.77A26.67 26.67 0 1 1 533.33 360a13.34 13.34 0 0 0 26.67 0 53.28 53.28 0 0 0-40-51.44v-1.9a13.34 13.34 0 0 0-26.67 0v1.9a53.22 53.22 0 0 0 13.33 104.77 26.67 26.67 0 0 1 0 53.34ZM467.06 613.33h-.13a13.41 13.41 0 1 0 .13 0ZM627.06 640a13.34 13.34 0 1 0 0-26.67h-.13a13.34 13.34 0 0 0 .13 26.67ZM547.06 613.33h-.13a13.41 13.41 0 1 0 .13 0ZM493.33 160h53.33a13.34 13.34 0 1 0 0-26.67h-53.33a13.34 13.34 0 0 0 0 26.67ZM307.06 613.33h-.13a13.41 13.41 0 1 0 .13 0ZM146.67 453.33h26.66a13.33 13.33 0 1 0 0-26.66h-26.66a13.33 13.33 0 1 0 0 26.66ZM146.67 533.33h26.66a13.33 13.33 0 0 0 0-26.66h-26.66a13.33 13.33 0 1 0 0 26.66Z" />
          <path d="M680 0H13.33A13.33 13.33 0 0 0 0 13.33v773.33a13.33 13.33 0 0 0 22.76 9.43l70.57-70.57 70.58 70.57a13.32 13.32 0 0 0 18.85 0l43.9-43.91 43.91 43.91a13.32 13.32 0 0 0 18.85 0l43.91-43.91 17.24 17.24a13.3 13.3 0 0 0 18.85 0l43.91-43.9 43.91 43.9a13.3 13.3 0 0 0 18.85 0l30.57-30.57 30.58 30.57a13.3 13.3 0 0 0 18.85 0l70.57-70.57 43.91 43.91a7.67 7.67 0 0 0 .84.77 13.37 13.37 0 0 0 8 3.09h1.5a13.29 13.29 0 0 0 7.18-2.74 12.27 12.27 0 0 0 2-1.85 13.32 13.32 0 0 0 3.26-8.69v-720A13.33 13.33 0 0 0 680 0Zm-13.34 701.14-30.57-30.57a13.32 13.32 0 0 0-18.85 0l-70.58 70.57-30.57-30.57a13.32 13.32 0 0 0-18.85 0l-30.58 30.57-43.9-43.9a13.31 13.31 0 0 0-18.86 0l-43.9 43.9-17.24-17.24a13.33 13.33 0 0 0-18.86 0L280 767.81l-43.91-43.91a13.34 13.34 0 0 0-18.85 0l-43.91 43.91-70.57-70.57a13.3 13.3 0 0 0-18.85 0l-57.24 57.24V26.67h640v674.47Z" />
          <path d="M67.07 640a13.34 13.34 0 0 0 0-26.67h-.14a13.34 13.34 0 0 0 .14 26.67ZM146.67 373.33h26.66a13.33 13.33 0 1 0 0-26.66h-26.66a13.33 13.33 0 1 0 0 26.66ZM387.06 640a13.34 13.34 0 1 0 0-26.67h-.13a13.34 13.34 0 0 0 .13 26.67ZM226.66 293.33h80a13.33 13.33 0 1 0 0-26.66h-80a13.33 13.33 0 1 0 0 26.66ZM146.67 293.33h26.66a13.33 13.33 0 1 0 0-26.66h-26.66a13.33 13.33 0 1 0 0 26.66ZM360 346.67H226.66a13.33 13.33 0 1 0 0 26.66H360a13.33 13.33 0 0 0 0-26.66ZM146.67 160H360a13.34 13.34 0 0 0 0-26.67H146.67a13.34 13.34 0 1 0 0 26.67Z" />
        </svg>
      );

    case "contract":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 597.67 725"
        >
          <path
            d="M12.5 12.5h572.67v700H12.5z"
            style={{
              fill: "none",
              stroke: "#020202",
              strokeMiterlimit: 333.3333206176758,
              strokeWidth: 25,
            }}
          />
          <path
            d="M171.5 617.17v-32l63.67-95.34 31.66 95.34 64-31.67 31.67 31.67h95.33"
            style={{
              fill: "none",
              stroke: "#020202",
              strokeMiterlimit: 333.3333206176758,
              strokeWidth: 25,
            }}
          />
          <path d="M328 270.18H221.33a13.34 13.34 0 0 0 0 26.67H328a13.34 13.34 0 0 0 0-26.67ZM141.33 296.85H168a13.34 13.34 0 0 0 0-26.67h-26.67a13.34 13.34 0 0 0 0 26.67ZM141.33 376.85H168a13.34 13.34 0 0 0 0-26.67h-26.67a13.34 13.34 0 0 0 0 26.67ZM141.33 216.85H168a13.34 13.34 0 0 0 0-26.67h-26.67a13.34 13.34 0 0 0 0 26.67ZM221.33 136.85h80a13.34 13.34 0 1 0 0-26.67h-80a13.34 13.34 0 0 0 0 26.67ZM141.33 136.85H168a13.34 13.34 0 0 0 0-26.67h-26.67a13.34 13.34 0 0 0 0 26.67ZM354.67 190.18H221.33a13.34 13.34 0 0 0 0 26.67h133.34a13.34 13.34 0 0 0 0-26.67Z" />
        </svg>
      );

    case "update":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 491.67 625"
        >
          <path
            d="M279.17 12.5h-200A66.67 66.67 0 0 0 12.5 79.17v166.66M279.17 12.5l200 200m-200-200v166.67a33.33 33.33 0 0 0 33.33 33.33h166.67m0 0v333.33a66.67 66.67 0 0 1-66.67 66.67H179.17A133.34 133.34 0 0 1 45.83 479.17h0a133.34 133.34 0 0 1 133.34-133.34h100m0 0-100-100m100 100-100 100"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "delete":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 558.33 558.33"
        >
          <path
            d="M545.83 345.83V112.5a100 100 0 0 0-100-100H279.17m266.66 333.33-216.66 200m216.66-200h-150a66.66 66.66 0 0 0-66.66 66.67v133.33m0 0H112.5a100 100 0 0 1-100-100V279.17M12.5 12.5l83.33 83.33m83.34 83.34L95.83 95.83m0 0 83.34-83.33M95.83 95.83 12.5 179.17"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "create":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 491.67 625"
        >
          <path
            d="M279.17 12.5h-200A66.67 66.67 0 0 0 12.5 79.17h0v166.66M279.17 12.5l200 200m-200-200v166.67a33.33 33.33 0 0 0 33.33 33.33h166.67m0 0v333.33a66.67 66.67 0 0 1-66.67 66.67H179.17A133.34 133.34 0 0 1 45.83 479.17h0a133.34 133.34 0 0 1 133.34-133.34h100m-71.28 74-1.1-141.42M279.17 345.83"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "view":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 556.11 358.33"
        >
          <path
            d="M278.06 12.5c-97.57 0-197.72 53.2-263.61 165 65 114.12 165.64 168.37 263.61 168.37s198.65-54.25 263.61-168.37c-65.9-111.8-166.05-165-263.61-165Zm0 266.67a100 100 0 1 1 100-100 100 100 0 0 1-100 100Z"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 25,
            }}
          />
          <circle
            cx={278.06}
            cy={179.17}
            r={33.33}
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 25,
            }}
            transform="rotate(-13.28 278.123 179.214)"
          />
        </svg>
      );

    case "modify":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558.33 625">
          <path
            d="M48.17 179.17C121.75 51.59 284.82 7.82 412.4 81.4a266.65 266.65 0 0 1 133.43 231.1M510.17 445.83c-73.58 127.58-236.66 171.35-364.23 97.77A266.64 266.64 0 0 1 12.5 312.5"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
          <path
            d="M45.83 12.5v166.67H212.5M512.5 612.5V445.83H345.83"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "trash":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 558.33 625"
        >
          <path
            d="M12.5 145.83h533.33M79.17 245.83l56.71 311.93a66.66 66.66 0 0 0 65.59 54.74h155.39a66.66 66.66 0 0 0 65.59-54.74l56.72-311.93M179.17 79.17a66.66 66.66 0 0 1 66.66-66.67h66.67a66.67 66.67 0 0 1 66.67 66.67v66.66h-200Z"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "copy":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 625 691.67"
        >
          <path
            d="M112.5 312.5c0-94.28 0-141.42 29.29-170.71s76.43-29.29 170.71-29.29h100c94.28 0 141.42 0 170.71 29.29s29.29 76.43 29.29 170.71v166.67c0 94.28 0 141.42-29.29 170.71s-76.43 29.29-170.71 29.29h-100c-94.28 0-141.42 0-170.71-29.29s-29.29-76.43-29.29-170.71Z"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 133.3333282470703,
              strokeWidth: 25,
            }}
          />
          <path
            d="M112.5 579.17a100 100 0 0 1-100-100v-200c0-125.71 0-188.56 39.05-227.62s101.91-39 227.62-39H412.5a100 100 0 0 1 100 100"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 133.3333282470703,
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "plus":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 425 425"
        >
          <path
            d="M12.5 212.5h400m-200-200v400"
            style={{
              fill: "none",
              stroke: "#FFF",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "minus":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 420.98 25"
        >
          <path
            d="M12.5 12.5h395.98"
            style={{
              fill: "none",
              stroke: "#FFF",
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: 25,
            }}
          />
        </svg>
      );

    case "company":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 597.67 725"
        >
          <path
            fill="none"
            stroke="#020202"
            strokeMiterlimit="333.333"
            strokeWidth="25"
            d="M12.5 12.5H585.17V712.5H12.5z"
          />
          <path
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M460.64 74.84H137a53.94 53.94 0 00-53.94 53.94v521.38h431.52V128.78a53.94 53.94 0 00-53.94-53.94zM262.88 614.2V506.33h71.91V614.2zm215.74 0H370.75V488.35a18 18 0 00-18-18H244.9a18 18 0 00-18 18V614.2H119.05V128.78a18 18 0 0118-18h323.59a18 18 0 0118 18zM191 182.71h71.92v71.92H191zm143.83 0h71.92v71.92h-71.96zM191 326.54h71.92v71.92H191zm143.83 0h71.92v71.92h-71.96z"
            data-name="Path 133"
          />
        </svg>
      );

    default:
      return "";
  }
};

export default icon;
