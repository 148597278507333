import {
  Row,
  Col,
  Container,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import icon from "../icons/icon";
import Globals from "../Globals";
import "../../../styles/main.scss";

const Dashboard = () => {
  return (
    <Globals>
      <Container className={"pt-4"}>
        <Row>
          <Col lg={8} md={12}>
            <h1 className="page-title">Dashboard</h1>
          </Col>
          <Col lg={4} md={12}>
            <div className="search-group">
              <input
                type="search"
                className="search"
                placeholder="Search for the modules"
                width="300px"
              />
              <span className="icon">{icon("search")}</span>
            </div>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row>
          <Col lg={8} md={12}>
            <Row>
              <Col lg={4} md={12}>
                <Link
                  className={"text-decoration-none"}
                  to={"/admin/companies"}
                >
                  <Card>
                    <CardBody className="text-center p-4">
                      <div className="mt-2 py-4">
                        <span className="icon-big mx-auto">
                          {icon("company")}
                        </span>
                      </div>
                      <CardTitle tag="h5">
                        {/*dangerouslySetInnerHTML={{ __html: 'Some Title' }}*/}
                        Companies
                      </CardTitle>
                      <CardText>
                        {"Create a company with its information."}
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col className={"pt-4 pt-lg-0"} lg={4} md={12}>
                <Link className={"text-decoration-none"} to={"/admin/invoices"}>
                  <Card>
                    <CardBody className="text-center p-4">
                      <div className="mt-2 py-4">
                        <span className="icon-big mx-auto">
                          {icon("invoice")}
                        </span>
                      </div>
                      <CardTitle tag="h5">
                        {/*dangerouslySetInnerHTML={{ __html: 'Some Title' }}*/}
                        Invoices
                      </CardTitle>
                      <CardText>
                        {"Create an invoice in a specific date."}
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col className={"pt-4 pt-lg-0"} lg={4} md={12}>
                <Link
                  className={"text-decoration-none"}
                  to={"/admin/contracts"}
                >
                  <Card>
                    <CardBody className="text-center p-4">
                      <div className="mt-2 py-4">
                        <span className="icon-big mx-auto">
                          {icon("contract")}
                        </span>
                      </div>
                      <CardTitle tag="h5">Contract</CardTitle>
                      <CardText>
                        {"Create a contract in a specific date."}
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col className={"pt-4 pt-lg-0"} lg={4} md={12}>
            <div className="faq-section">
              <h3 className="faq-title">Frequently searched</h3>
              <ul className="faqs">
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my brand performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my email campaign performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top performing pages?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top traffic channels?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How much traffic is my website getting?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How can I generate more traffic?
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Globals>
  );
};

export default Dashboard;
