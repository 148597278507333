import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import icon from "../icons/icon";
import Globals from "../Globals";
import "../../../styles/main.scss";
import useSubmissionOnEnter from "../../../hooks/useSubmissionOnEnter";
import GlobalContext from "../../../context/GlobalContext";

const Settings = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [tab, setTab] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [address, setAddress] = useState("");
  const [requiresApproval, setRequiresApproval] = useState(true);
  const [sendApprovalNotification, setsendApprovalNotification] =
    useState(true);
  useSubmissionOnEnter("submit");
  const BACKEND_URL = useContext(GlobalContext);

  useEffect(() => {
    getCoreSettings();
  }, []);

  const getCoreSettings = async () => {
    const res = await fetch(`${BACKEND_URL}/api/core`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();
    if (!res.ok) {
      setError(json.message);
    }

    if (res.ok) {
      setRequiresApproval(json.coreSettings.adminApproval);
      setsendApprovalNotification(json.coreSettings.sendApprovalNotification);
      setName(json.coreSettings.name);
      setEmail(json.coreSettings.email);
      setDomain(json.coreSettings.domain);
      setAddress(json.coreSettings.address);
    }
  };

  const handleSubmit = async () => {
    const data = {
      adminApproval: requiresApproval,
      sendApprovalNotification: sendApprovalNotification,
      name,
      email,
      domain,
      address,
    };

    const res = await fetch(`${BACKEND_URL}/api/core/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      body: JSON.stringify(data),
    });

    const json = await res.json();
    if (!res.ok) {
      setError(json.message);
    }

    if (res.ok) {
      setMessage(json.message);
    }
  };

  return (
    <Globals>
      <Container className={"pt-4"}>
        <Row>
          <Col lg={8} md={12}>
            <h1 className="page-title">Settings</h1>
          </Col>
          <Col lg={4} md={12}>
            <div className="search-group">
              <input
                type="search"
                className="search"
                placeholder="Search for the modules"
                width="300px"
              />
              <span className="icon">{icon("search")}</span>
            </div>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row>
          <Col lg={8} md={12}>
            <Row>
              <Col>
                {error && (
                  <Alert
                    key={"danger"}
                    color={"danger"}
                    toggle={() => setError("")}
                  >
                    {error}
                  </Alert>
                )}
                {message && (
                  <Alert
                    key={"success"}
                    color={"success"}
                    toggle={() => setMessage()}
                  >
                    {message}
                  </Alert>
                )}

                <Nav className={"mb-4"} tabs>
                  <NavItem
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    <NavLink
                      className={tab === 1 && "active"}
                      onClick={function noRefCheck() {}}
                    >
                      Company Settings
                    </NavLink>
                  </NavItem>
                  <NavItem
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    <NavLink
                      className={tab === 2 && "active"}
                      onClick={function noRefCheck() {}}
                    >
                      App Settings
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={tab}>
                  <TabPane tabId={1}>
                    <Form>
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          id={"name"}
                          name={"name"}
                          type={"text"}
                          placeholder={"Name"}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>

                      <Row>
                        <Col lg={6} md={12}>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              id={"email"}
                              name={"email"}
                              type={"email"}
                              placeholder={"Email"}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={6} md={12}>
                          <FormGroup>
                            <Label>Domain</Label>
                            <Input
                              id={"domain"}
                              name={"domain"}
                              type={"text"}
                              placeholder={"Domain"}
                              value={domain}
                              onChange={(e) => setDomain(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup>
                        <Label>Company Address</Label>
                        <Input
                          id={"address"}
                          name={"address"}
                          type={"textarea"}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </FormGroup>

                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={requiresApproval}
                          onClick={() => {
                            setRequiresApproval(!requiresApproval);
                          }}
                        />
                        <Label check>Admin approvals for new accounts?</Label>
                      </FormGroup>

                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={sendApprovalNotification}
                          onClick={() => {
                            setsendApprovalNotification(
                              !sendApprovalNotification,
                            );
                          }}
                        />
                        <Label check>Send approval notifications?</Label>
                      </FormGroup>
                    </Form>
                  </TabPane>
                  <TabPane tabId={2}>Pane 2</TabPane>
                </TabContent>
                <Button
                  id={"submit"}
                  className={"mt-4 float-end"}
                  color={"primary"}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
          <Col className={"pt-4 pt-lg-0"} lg={4} md={12}>
            <div className="faq-section">
              <h3 className="faq-title">Frequently searched</h3>
              <ul className="faqs">
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my brand performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my email campaign performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top performing pages?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top traffic channels?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How much traffic is my website getting?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How can I generate more traffic?
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Globals>
  );
};

export default Settings;
