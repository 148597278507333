const Footer = () => {
  return (
    <div className="footer">
      <p className="text-muted">
        © {process.env.APP_YEAR} - {new Date().getFullYear()}{" "}
        {process.env.APP_NAME} | All Right Reserved{" "}
      </p>
    </div>
  );
};

export default Footer;
