import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Table,
  Tooltip,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import { DatePicker } from "reactstrap-date-picker";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../icons/icon";
import Globals from "../Globals";
import "../../../styles/main.scss";
import ClipboardJS from "clipboard";
import useSubmissionOnEnter from "../../../hooks/useSubmissionOnEnter";
import getRandomString from "../../../helpers/getRandomString";
import invalidateToken from "../../../helpers/invalidateToken";
import GlobalContext from "../../../context/GlobalContext";

const Invoices = () => {
  const [number, setNumber] = useState("");
  const [secret, setSecret] = useState("");
  const [to, setTo] = useState("");
  const [toRole, setToRole] = useState("");
  const [toLabel, setToLabel] = useState("");
  const [toRoleLabel, setToRoleLabel] = useState("");
  const [date, setDate] = useState("");
  const [total, setTotal] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [charges, setCharges] = useState(0);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [mainMessage, setMainMessage] = useState("");
  const [mainError, setMainError] = useState("");
  const [invokeUpdate, setInvokeUpdate] = useState("");
  const [emptyFields, setEmptyFields] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([
    { index: 1, taskNumber: "", task: "", detail: "", log: "0" },
  ]);
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [paymentMonth, setPaymentMonth] = useState("");
  const [monthsList, setMonthsList] = useState([]);
  const navigate = useNavigate();
  useSubmissionOnEnter("submit");
  const BACKEND_URL = useContext(GlobalContext);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const [viewTooltipOpen, setViewTooltipOpen] = useState(false);
  const [updateTooltipOpen, setUpdateTooltipOpen] = useState(false);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

  new ClipboardJS(".copy");

  useEffect(() => {
    getInvoices();
  }, [page]);

  useEffect(() => {
    getUsers();
    getNumber();
    getCompanies();
    setSecret(getRandomString(32));
    setDate(new Date(Date.now()).toLocaleDateString("en-US"));

    let startYear = 2018;
    let endYear = new Date().getFullYear() + 1;
    setMonthsList(generateMonthList(startYear, endYear));
  }, []);

  const getNumber = async () => {
    const res = await fetch(`${BACKEND_URL}/api/invoices/next`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
      setNumber(json.number + Math.floor(Math.random() * (50 - 12 + 1)) + 12);
    }

    if (res.ok) {
      setNumber(json.number + Math.floor(Math.random() * (50 - 12 + 1)) + 12);
    }
  };

  const getInvoices = async () => {
    const res = await fetch(
      `${BACKEND_URL}/api/invoices?page=${page}&size=18&sort=-1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      },
    );

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
      setInvoices([]);
      setPage(0);
      setTotalPages(0);
    }

    if (res.ok) {
      setInvoices(json.invoices);
      setTotalPages(json.totalPages || 0);
    }
  };

  const getUsers = async () => {
    const res = await fetch(`${BACKEND_URL}/api/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
      setUsers([]);
    }

    if (res.ok) {
      setUsers(json);
    }
  };

  const getCompanies = async () => {
    const res = await fetch(`${BACKEND_URL}/api/companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
      setCompanies([]);
    }

    if (res.ok) {
      setCompanies(json.companies);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    const res = await fetch(`${BACKEND_URL}/api/invoices`, {
      method: "POST",
      body: JSON.stringify({
        number,
        secret,
        to,
        toRole,
        toLabel,
        toRoleLabel,
        date,
        total,
        bonus,
        charges,
        items,
        company,
        paymentMonth,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }

      setError(json.message);
      setEmptyFields(json.emptyFields);
    }

    if (res.ok) {
      setError("");
      setEmptyFields([]);
      setNumber("");
      setSecret("");
      setTo("");
      setToRole("");
      setToLabel("");
      setToRoleLabel("");
      setCharges(0);
      setTotal(0);
      setBonus(0);
      setItems([]);
      setCompany("");
      setPaymentMonth("");
      setSecret(getRandomString(32));
      setDate(new Date(Date.now()).toLocaleDateString("en-US"));
      setMessage(json.message);
      getInvoices();
      getNumber();
    }
  };

  const setupUpdate = async (id) => {
    setInvokeUpdate(id);
    const res = await fetch(`${BACKEND_URL}/api/invoices/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();
    if (!res.ok) {
      setMainError("Could not fetch the record to update it.");
    }

    if (res.ok) {
      setError("");
      setEmptyFields([]);
      setNumber(json.number);
      setSecret(json.secret);
      setTo(json.to);
      setToRole(json.toRole);
      setToLabel(json.toLabel);
      setToRoleLabel(json.toRoleLabel);
      setDate(json.date);
      setTotal(json.total);
      setBonus(json.bonus);
      setCharges(json.charges);
      setItems(json.items);
      setCompany(json.company);
      json.paymentMonth
        ? setPaymentMonth(json.paymentMonth)
        : setPaymentMonth("");
    }
  };

  const cancelUpdate = () => {
    setError("");
    setEmptyFields([]);
    setNumber("");
    setSecret("");
    setTo("");
    setToRole("");
    setCharges(0);
    setTotal(0);
    setBonus(0);
    setItems([{ index: 1, taskNumber: "", task: "", detail: "", log: "0" }]);
    setCompany("");
    setPaymentMonth("");
    setInvokeUpdate("");
    setTo("");
    setToLabel("");
    setToRoleLabel("");
    getNumber();
    setSecret(getRandomString(32));
    setDate(new Date(Date.now()).toLocaleDateString("en-US"));
  };

  const handleUpdate = async (id) => {
    const res = await fetch(`${BACKEND_URL}/api/invoices/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        number,
        secret,
        to,
        toRole,
        toLabel,
        toRoleLabel,
        date,
        total,
        bonus,
        charges,
        items,
        company,
        paymentMonth,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();
    if (!res.ok) {
      setError(json.message);
      setEmptyFields(json.emptyFields);
    }

    if (res.ok) {
      setError("");
      setEmptyFields([]);
      setNumber("");
      setSecret("");
      setTo("");
      setToRole("");
      setToLabel("");
      setToRoleLabel("");
      setCharges(0);
      setTotal(0);
      setBonus(0);
      setItems([]);
      setCompany("");
      setPaymentMonth("");
      setMessage(json.message);
      getInvoices();
      getNumber();
      setSecret(getRandomString(32));
      setDate(new Date(Date.now()).toLocaleDateString("en-US"));
    }

    setInvokeUpdate("");
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      const res = await fetch(`${BACKEND_URL}/api/invoices/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const json = await res.json();
      if (!res.ok) {
        setMainError(json.message);
      }

      if (res.ok) {
        setMainMessage(json.message);
        getInvoices();
        getNumber();
      }
    }
  };

  const addItemHandler = () => {
    setItems([
      ...items,
      {
        index: items.length + 1,
        taskNumber: "",
        task: "",
        detail: "",
        log: "",
      },
    ]);
  };

  const deleteItemHandler = (e, index) => {
    e.preventDefault();
    setItems([
      ...items
        .filter((item) => item.index !== index)
        .map((i) => (i.index > index ? { ...i, index: i.index - 1 } : i)),
    ]);
  };

  const generateMonthList = (startYear, endYear) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthList = [];

    for (let year = startYear; year <= endYear; year++) {
      for (let month = 0; month < months.length; month++) {
        monthList.push(`${months[month]} ${year}`);
      }
    }

    return monthList;
  };

  return (
    <Globals>
      <Container className={"py-5"}>
        <Row>
          <Col lg={8} md={12}>
            <h1 className="page-title">Invoices</h1>
          </Col>
          <Col lg={4} md={12}>
            <div className="search-group">
              <input
                type="search"
                className="search"
                placeholder="Search for the modules"
                width="300px"
              />
              <span className="icon">{icon("search")}</span>
            </div>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row>
          <Col lg={5} md={12}>
            {error && (
              <Alert
                key={"danger"}
                color={"danger"}
                toggle={() => setError("")}
              >
                {error}
              </Alert>
            )}
            {message && (
              <Alert
                key={"success"}
                color={"success"}
                toggle={() => setMessage()}
              >
                {message}
              </Alert>
            )}
            <Form>
              <Row>
                <Col lg={12} xl={6}>
                  <FormGroup>
                    <Label>
                      Number <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      id="number"
                      name="number"
                      placeholder="Invoice Number"
                      type="text"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      className={
                        emptyFields && emptyFields.includes("number")
                          ? "error"
                          : ""
                      }
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg={12} xl={6}>
                  <FormGroup>
                    <Label>
                      Secret <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      id="secret"
                      name="secret"
                      placeholder="Invoice Secret"
                      type="text"
                      value={secret}
                      onChange={(e) => setSecret(e.target.value)}
                      className={
                        emptyFields && emptyFields.includes("secret")
                          ? "error"
                          : ""
                      }
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xl={6}>
                  <FormGroup>
                    <Label>
                      Date <span style={{ color: "red" }}>*</span>
                    </Label>
                    <DatePicker
                      id="date"
                      value={date}
                      onChange={(e) => setDate(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12} xl={6}>
                  <FormGroup>
                    <Label>
                      To <span style={{ color: "red" }}>*</span>
                    </Label>
                    {users && users.length > 0 && (
                      <Select
                        id="to"
                        name="to"
                        placeholder="Invoice To"
                        isSearchable
                        options={users.map((user) => {
                          return {
                            label: user.firstName + " " + user.lastName,
                            value: user.username,
                          };
                        })}
                        value={{ label: toLabel, value: to }}
                        onChange={(e) => {
                          setToLabel(e.label);
                          setTo(e.value);
                        }}
                        className={
                          emptyFields && emptyFields.includes("to")
                            ? "error"
                            : ""
                        }
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <Label>
                      Role <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Select
                      id="toRole"
                      name="toRole"
                      placeholder="Title"
                      isSearchable
                      options={[
                        { label: "Web Developer", value: "web-developer" },
                        {
                          label: "Associate Web Developer",
                          value: "associate-web-developer",
                        },
                        {
                          label: "Senior Web Developer",
                          value: "senior-web-developer",
                        },
                        {
                          label: "Front-end Drupal Developer",
                          value: "front-end-drupal-developer",
                        },
                        {
                          label: "Lead Web Developer",
                          value: "lead-web-developer",
                        },
                      ]}
                      value={{ label: toRoleLabel, value: toRole }}
                      onChange={(e) => {
                        setToRoleLabel(e.label);
                        setToRole(e.value);
                      }}
                      className={
                        emptyFields && emptyFields.includes("to") ? "error" : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12} xl={6}>
                  <FormGroup>
                    <Label>
                      Company <span style={{ color: "red" }}>*</span>
                    </Label>
                    {companies && companies.length > 0 && (
                      <Select
                        id="company"
                        name="company"
                        placeholder="Company"
                        isSearchable
                        options={companies.map((company) => {
                          return { label: company.name, value: company._id };
                        })}
                        value={{
                          label: (
                            companies.find((o) => o._id === company) || {}
                          ).name,
                          value: company,
                        }}
                        onChange={(e) => {
                          setCompany(e.value);
                        }}
                        className={
                          emptyFields && emptyFields.includes("company")
                            ? "error"
                            : ""
                        }
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col lg={12} xl={6}>
                  <FormGroup>
                    <Label>
                      Payment month <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Select
                      id="payment-month"
                      name="payment-month"
                      placeholder="Jan 2020"
                      isSearchable
                      isMulti
                      value={
                        paymentMonth &&
                        paymentMonth.length > 0 &&
                        paymentMonth.split(", ").map((month) => {
                          return { label: month, value: month };
                        })
                      }
                      options={monthsList
                        .filter((i) =>
                          paymentMonth && paymentMonth.length > 0
                            ? !paymentMonth.split(", ").includes(i)
                            : true,
                        )
                        .map((month) => {
                          return { label: month, value: month };
                        })}
                      onChange={(e) => {
                        setPaymentMonth(e.map((i) => i.value).join(", "));
                      }}
                      className={
                        emptyFields && emptyFields.includes("company")
                          ? "error"
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {items.map((item, v) => {
                return (
                  <Card className={"my-4"}>
                    <CardBody>
                      <CardTitle tag={"h4"}>Item {v + 1}</CardTitle>
                      <Row>
                        <Col lg={2} xl={2}>
                          <FormGroup>
                            <Label>Task #</Label>
                            <Input
                              id={`task-number-${v + 1}`}
                              name={`task-number-${v + 1}`}
                              placeholder="Task #"
                              type="text"
                              value={item.taskNumber}
                              onChange={(e) => {
                                setItems(
                                  items.map((item) =>
                                    item.index === v + 1
                                      ? {
                                          ...item,
                                          taskNumber: e.target.value,
                                        }
                                      : item,
                                  ),
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12} xl={3}>
                          <FormGroup>
                            <Label>Task</Label>
                            <Input
                              id={`task${v + 1}`}
                              name={`task${v + 1}`}
                              placeholder="Task"
                              type="text"
                              value={item.task}
                              onChange={(e) => {
                                setItems(
                                  items.map((item) =>
                                    item.index === v + 1
                                      ? {
                                          ...item,
                                          task: e.target.value,
                                        }
                                      : item,
                                  ),
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12} xl={5}>
                          <FormGroup>
                            <Label>Detail</Label>
                            <Input
                              id={`detail${v + 1}`}
                              name={`detail${v + 1}`}
                              placeholder="Detail"
                              type="text"
                              value={item.detail}
                              onChange={(e) => {
                                setItems(
                                  items.map((item) =>
                                    item.index === v + 1
                                      ? {
                                          ...item,
                                          detail: e.target.value,
                                        }
                                      : item,
                                  ),
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12} xl={2}>
                          <FormGroup>
                            <Label>Log</Label>
                            <Input
                              id={`log${v + 1}`}
                              name={`log${v + 1}`}
                              placeholder="Log"
                              type="text"
                              value={item.log}
                              onChange={(e) => {
                                setItems(
                                  items.map((item) =>
                                    item.index === v + 1
                                      ? {
                                          ...item,
                                          log: e.target.value,
                                        }
                                      : item,
                                  ),
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            size="sm"
                            className={"float-end"}
                            color={"danger"}
                            onClick={(e) => deleteItemHandler(e, v + 1)}
                          >
                            <span className="icon">{icon("minus")}</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}

              <FormGroup>
                <Label>Logged Hours</Label>
                <Input
                  value={
                    items &&
                    items.length &&
                    items.reduce((r, cur) => {
                      return r + parseInt(cur.log);
                    }, 0)
                  }
                  disabled
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Charges <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="charges"
                  name="charges"
                  placeholder="Charges"
                  type="text"
                  value={charges}
                  onChange={(e) => setCharges(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("charges")
                      ? "error"
                      : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Bonus <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="bonus"
                  name="bonus"
                  placeholder="Bonus"
                  type="text"
                  value={bonus}
                  onChange={(e) => setBonus(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("bonus") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Basic Pay <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="total"
                  name="total"
                  placeholder="Invoice Total"
                  type="text"
                  value={total}
                  onChange={(e) => setTotal(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("total") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Gross Total <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="gross-total"
                  name="gross-total"
                  placeholder="0"
                  type="text"
                  value={
                    (charges || bonus || total) &&
                    parseInt(charges) + parseInt(bonus) + parseInt(total)
                  }
                  disabled
                />
              </FormGroup>
              <FormGroup className={"text-end"}>
                <ButtonGroup>
                  <Button color={"success"} onClick={addItemHandler}>
                    <span className="icon">{icon("plus")}</span>
                  </Button>
                  {!invokeUpdate && (
                    <Button
                      id={"submit"}
                      color="success"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                  {invokeUpdate && (
                    <>
                      <Button
                        id={"cancel"}
                        color="warning"
                        onClick={cancelUpdate}
                      >
                        Cancel
                      </Button>
                      <Button
                        id={"submit"}
                        color="success"
                        onClick={() => handleUpdate(invokeUpdate)}
                      >
                        Update
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </FormGroup>
            </Form>
          </Col>
          <Col className={"pt-4 pt-lg-0"} lg={7} md={12}>
            <Row>
              {mainError && (
                <Alert
                  key={"danger"}
                  color={"danger"}
                  toggle={() => setMainError("")}
                >
                  {mainError}
                </Alert>
              )}
              {mainMessage && (
                <Alert
                  key={"success"}
                  color={"success"}
                  toggle={() => setMainMessage("")}
                >
                  {mainMessage}
                </Alert>
              )}
              <Table>
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>To</th>
                    <th>Month</th>
                    <th>Year</th>
                    <th>Total</th>
                    <th width={128}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice) => {
                    return (
                      <tr key={invoice.number}>
                        <th scope="row">{invoice.number}</th>
                        <td>{invoice.toLabel}</td>
                        <td>{invoice.paymentMonth.slice(0, 3)}</td>
                        <td>
                          {new Date(invoice.date).toLocaleDateString("en-US", {
                            year: "numeric",
                          })}
                        </td>
                        <td>{invoice.bonus + invoice.total}</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              id={"copy"}
                              className={"copy"}
                              color={""}
                              size={"sm"}
                              data-clipboard-text={`${window.location.origin}/invoices/${invoice.number}/${invoice.secret}`}
                              onClick={() => setMainMessage("Copied!")}
                            >
                              <span className="icon sm">{icon("copy")}</span>
                            </Button>
                            <Tooltip
                              isOpen={copyTooltipOpen}
                              target="copy"
                              toggle={() =>
                                setCopyTooltipOpen(!copyTooltipOpen)
                              }
                            >
                              Copy this record
                            </Tooltip>
                            <Button id={"view"} color={""} size={"sm"}>
                              <span
                                className="icon sm"
                                onClick={() =>
                                  window.open(
                                    `/invoices/${invoice.number}/${invoice.secret}`,
                                    "_blank",
                                  )
                                }
                              >
                                {icon("view")}
                              </span>
                              <Tooltip
                                isOpen={viewTooltipOpen}
                                target="view"
                                toggle={() =>
                                  setViewTooltipOpen(!viewTooltipOpen)
                                }
                              >
                                View this record
                              </Tooltip>
                            </Button>
                            <Button
                              id={"update"}
                              color={""}
                              size={"sm"}
                              onClick={() => setupUpdate(invoice._id)}
                            >
                              <span className="icon sm">{icon("modify")}</span>
                            </Button>
                            <Tooltip
                              isOpen={updateTooltipOpen}
                              target="update"
                              toggle={() =>
                                setUpdateTooltipOpen(!updateTooltipOpen)
                              }
                            >
                              Modify this record
                            </Tooltip>
                            <Button
                              id={"delete"}
                              color={""}
                              size={"sm"}
                              onClick={() => handleDelete(invoice._id)}
                            >
                              <span className="icon sm">{icon("trash")}</span>
                            </Button>
                            <Tooltip
                              isOpen={deleteTooltipOpen}
                              target="delete"
                              toggle={() =>
                                setDeleteTooltipOpen(!deleteTooltipOpen)
                              }
                            >
                              Delete this record
                            </Tooltip>
                          </ButtonGroup>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className={"d-block text-center"}>
                  <Button
                    color={"secondary"}
                    onClick={() => {
                      setPage(Math.max(0, page - 1));
                    }}
                  >
                    Previous
                  </Button>
                  <Button color={"secondary"}>
                    Page {page + 1} of {totalPages}
                  </Button>
                  <Button
                    color={"secondary"}
                    onClick={() => {
                      setPage(Math.min(totalPages - 1, page + 1));
                    }}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Globals>
  );
};

export default Invoices;
