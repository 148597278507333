import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import useSubmissionOnEnter from "../hooks/useSubmissionOnEnter";
import GlobalContext from "../context/GlobalContext";

const LoginForm = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [show, setShow] = useState(true);
  const [emptyFields, setEmptyFields] = useState([]);
  const navigate = useNavigate();

  useSubmissionOnEnter("submit");
  const BACKEND_URL = useContext(GlobalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cred = {
      username,
      password,
    };

    const res = await fetch(`${BACKEND_URL}/api/login/`, {
      method: "POST",
      body: JSON.stringify(cred),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    if (!res.ok) {
      setError(json.message);
      setEmptyFields(json.emptyFields);
    }

    if (res.ok) {
      setError(null);
      setEmptyFields([]);
      setUsername("");
      setPassword("");

      const { token } = json;
      localStorage.setItem("jwt", token);
      navigate("/admin/dashboard");
    }

    setShow(true);
  };

  return (
    <Container className={"h-100"}>
      <Row className={"h-100"}>
        <Col
          className={"align-self-center offset-xl-3 offset-lg-2"}
          xl={6}
          lg={8}
          md={12}
        >
          {error && show && (
            <Alert
              key={"danger"}
              color={"danger"}
              toggle={() => setShow(false)}
            >
              {error}
            </Alert>
          )}

          <Card>
            <CardBody>
              <Form>
                <h3>Login</h3>
                <FormGroup
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Label>Email/Username</Label>
                  <Input
                    type="text"
                    placeholder={"Username"}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className={
                      emptyFields && emptyFields.includes("username")
                        ? "error"
                        : ""
                    }
                  />
                </FormGroup>

                <FormGroup
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Label>Password</Label>
                  <Input
                    type="password"
                    placeholder={"Password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className={
                      emptyFields && emptyFields.includes("password")
                        ? "error"
                        : ""
                    }
                  />
                </FormGroup>

                <FormGroup className={"text-end"}>
                  <ButtonGroup>
                    <Button
                      id={"submit"}
                      color="success"
                      onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </ButtonGroup>
                </FormGroup>

                <FormGroup className={"text-end"}>
                  <ButtonGroup>
                    <Button
                      size={"sm"}
                      color="secondary"
                      onClick={() => navigate("/recover-password")}
                    >
                      Recover Password
                    </Button>

                    <Button
                      size={"sm"}
                      color="secondary"
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
