import PasswordRecoveryForm from "../components/PasswordRecoveryForm";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RecoverPassword = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="home h-100">
      <div className="password-recovery-form h-100">
        {!isLoggedIn && <PasswordRecoveryForm setIsLoggedIn={setIsLoggedIn} />}
        {isLoggedIn && navigate("/")}
      </div>
    </div>
  );
};

export default RecoverPassword;
