import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import icon from "../icons/icon";
import Globals from "../Globals";
import "../../../styles/main.scss";
import { useContext, useEffect, useState } from "react";
import useSubmissionOnEnter from "../../../hooks/useSubmissionOnEnter";
import invalidateToken from "../../../helpers/invalidateToken";
import GlobalContext from "../../../context/GlobalContext";

const ProfileSettings = () => {
  const [user, setUser] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [isActive, setIsActive] = useState();
  const [account, setAccount] = useState();
  const [swift, setSwift] = useState();
  const [address, setAddress] = useState();
  const [message, setMessage] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [error, setError] = useState();
  const BACKEND_URL = useContext(GlobalContext);
  useSubmissionOnEnter("submit");

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await fetch(
      `${BACKEND_URL}/api/users/token/${localStorage.getItem("jwt")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      },
    );

    const json = await res.json();
    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
      setError(json.message);
    }

    if (res.ok) {
      setUser(json);
      setUsername(json.username);
      setEmail(json.email);
      setIsActive(json.isActive);
      setAccount(json.account);
      setSwift(json.swift);
      setAddress(json.address);
      setFirstName(json.firstName);
      setLastName(json.lastName);
    }
  };

  const handleSubmit = async () => {
    const res = await fetch(`${BACKEND_URL}/api/users/${user._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      body: JSON.stringify({
        username,
        email,
        isActive,
        account,
        swift,
        address,
        firstName,
        lastName,
      }),
    });

    const json = await res.json();
    if (!res.ok) {
      setError(json.message);
    }

    if (res.ok) {
      setMessage(json.message);
      setUsername(json.username);
      setEmail(json.email);
      setIsActive(json.isActive);
      setAccount(json.account);
      setSwift(json.swift);
      setAddress(json.address);
      setFirstName(json.firstName);
      setLastName(json.lastName);
    }
  };

  return (
    <Globals>
      <Container className={"pt-4"}>
        <Row>
          <Col lg={8} md={12}>
            <h1 className="page-title">Profile Settings</h1>
          </Col>
          <Col lg={4} md={12}>
            <div className="search-group">
              <input
                type="search"
                className="search"
                placeholder="Search for the modules"
                width="300px"
              />
              <span className="icon">{icon("search")}</span>
            </div>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row>
          <Col lg={8} md={12}>
            <Row>
              <Col>
                {error && (
                  <Alert
                    key={"danger"}
                    color={"danger"}
                    toggle={() => setError("")}
                  >
                    {error}
                  </Alert>
                )}
                {message && (
                  <Alert
                    key={"success"}
                    color={"success"}
                    toggle={() => setMessage()}
                  >
                    {message}
                  </Alert>
                )}

                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="firstname">First Name</Label>
                        <Input
                          id="firstname"
                          name="firstname"
                          placeholder="First Name"
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="lastname">Last Name</Label>
                        <Input
                          id="lastname"
                          name="lastname"
                          placeholder="Last Name"
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="username">Username</Label>
                    <Input
                      id="username"
                      name="username"
                      placeholder="Username"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="account">Account Number</Label>
                    <Input
                      id="account"
                      name="account"
                      placeholder="Account #"
                      type="text"
                      value={account}
                      onChange={(e) => setAccount(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="account">SWIFT</Label>
                    <Input
                      id="swift"
                      name="swift"
                      placeholder="SWIFT"
                      type="text"
                      value={swift}
                      onChange={(e) => setSwift(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="address">Address</Label>
                    <Input
                      id="address"
                      name="address"
                      type="textarea"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormGroup>

                  <Button
                    id={"submit"}
                    className={"mt-4 float-end"}
                    color={"primary"}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col className={"pt-4 pt-lg-0"} lg={4} md={12}>
            <div className="faq-section">
              <h3 className="faq-title">Frequently searched</h3>
              <ul className="faqs">
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my brand performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How is my email campaign performing?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top performing pages?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  What are my top traffic channels?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How much traffic is my website getting?
                </li>
                <li className="faq">
                  <span className="icon">{icon("check")}</span>
                  How can I generate more traffic?
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Globals>
  );
};

export default ProfileSettings;
