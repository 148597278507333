import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Table,
  Tooltip,
} from "reactstrap";

import { useState, useEffect, useContext } from "react";
import icon from "../icons/icon";
import Globals from "../Globals";
import "../../../styles/main.scss";
import ClipboardJS from "clipboard";
import useSubmissionOnEnter from "../../../hooks/useSubmissionOnEnter";
import invalidateToken from "../../../helpers/invalidateToken";
import GlobalContext from "../../../context/GlobalContext";

const Companies = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [address, setAddress] = useState("");
  const [vat, setVat] = useState("");
  const [inc, setInc] = useState("");
  const [logo, setLogo] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [mainMessage, setMainMessage] = useState("");
  const [mainError, setMainError] = useState("");
  const [invokeUpdate, setInvokeUpdate] = useState("");
  const [emptyFields, setEmptyFields] = useState([]);
  const [companies, setCompanies] = useState([]);
  useSubmissionOnEnter("submit");
  const BACKEND_URL = useContext(GlobalContext);
  const [updateTooltipOpen, setUpdateTooltipOpen] = useState(false);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

  new ClipboardJS(".copy");

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const res = await fetch(`${BACKEND_URL}/api/companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }
      setCompanies([]);
    }

    if (res.ok) {
      setCompanies(json.companies);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("domain", domain);
    formData.append("address", address);
    formData.append("vat", vat);
    formData.append("inc", inc);
    formData.append("logo", logo); // Assuming logo is a file input

    const res = await fetch(`${BACKEND_URL}/api/companies`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();

    if (!res.ok) {
      if (res.status === 401) {
        invalidateToken();
      }

      setError(json.message);
      setEmptyFields(json.emptyFields);
    }

    if (res.ok) {
      setError("");
      setEmptyFields([]);
      setName("");
      setEmail("");
      setDomain("");
      setAddress("");
      setVat("");
      setInc("");
      setLogo("");
      setMessage(json.message);
      getCompanies();
    }
  };

  const setupUpdate = async (id) => {
    setInvokeUpdate(id);
    const res = await fetch(`${BACKEND_URL}/api/companies/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();
    if (!res.ok) {
      setMainError("Could not fetch the record to update it.");
    }

    if (res.ok) {
      setError("");
      setEmptyFields([]);
      setName(json.name);
      setEmail(json.email);
      setDomain(json.domain);
      setAddress(json.address);
      setVat(json.vat);
      setInc(json.inc);
      setLogo(json.logo);
    }
  };

  const cancelUpdate = () => {
    setError("");
    setEmptyFields([]);
    setName("");
    setEmail("");
    setDomain("");
    setAddress("");
    setVat("");
    setInc("");
    setLogo("");
    setInvokeUpdate("");
  };

  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("domain", domain);
    formData.append("address", address);
    formData.append("vat", vat);
    formData.append("inc", inc);
    formData.append("logo", logo);

    const res = await fetch(`${BACKEND_URL}/api/companies/${id}`, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();
    if (!res.ok) {
      setError(json.message);
      setEmptyFields(json.emptyFields);
    }

    if (res.ok) {
      setError("");
      setEmptyFields([]);
      setName("");
      setEmail("");
      setDomain("");
      setAddress("");
      setVat("");
      setInc("");
      setLogo("");
      setMessage(json.message);
      getCompanies();
    }

    setInvokeUpdate("");
  };

  const handleDelete = async (id) => {
    const res = await fetch(`${BACKEND_URL}/api/companies/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const json = await res.json();
    if (!res.ok) {
      setMainError(json.message);
    }

    if (res.ok) {
      setMainMessage(json.message);
      getCompanies();
    }
  };

  return (
    <Globals>
      <Container className={"pt-4"}>
        <Row>
          <Col lg={8} md={12}>
            <h1 className="page-title">Companies</h1>
          </Col>
          <Col lg={4} md={12}>
            <div className="search-group">
              <input
                type="search"
                className="search"
                placeholder="Search for the modules"
                width="300px"
              />
              <span className="icon">{icon("search")}</span>
            </div>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row>
          <Col lg={3} md={12}>
            {error && (
              <Alert
                key={"danger"}
                color={"danger"}
                toggle={() => setError("")}
              >
                {error}
              </Alert>
            )}
            {message && (
              <Alert
                key={"success"}
                color={"success"}
                toggle={() => setMessage()}
              >
                {message}
              </Alert>
            )}
            <Form enctype="multipart/form-data">
              <FormGroup>
                <Label>
                  Name <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("name") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Email <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("email") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Domain <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id="domain"
                  name="domain"
                  placeholder="Domain"
                  type="text"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("domain") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Address <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id={"address"}
                  name={"address"}
                  type={"textarea"}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("address")
                      ? "error"
                      : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Vat <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id={"vat"}
                  name={"vat"}
                  type={"text"}
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("vat") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Inc <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id={"inc"}
                  name={"inc"}
                  type={"text"}
                  value={inc}
                  onChange={(e) => setInc(e.target.value)}
                  className={
                    emptyFields && emptyFields.includes("inc") ? "error" : ""
                  }
                />
              </FormGroup>

              {inc && typeof logo === "object" && (
                <img
                  height={150}
                  src={`${URL.createObjectURL(logo)}`}
                  alt={"Comapny Logo"}
                />
              )}
              {inc && typeof logo !== "object" && (
                <img height={150} src={`${logo}`} alt={"Comapny Logo"} />
              )}
              <FormGroup>
                <Label>
                  Logo <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  id={"logo"}
                  name={"logo"}
                  type={"file"}
                  onChange={(e) => setLogo(e.target.files[0])}
                  className={
                    emptyFields && emptyFields.includes("logo") ? "error" : ""
                  }
                />
              </FormGroup>

              <FormGroup className={"text-end"}>
                <ButtonGroup>
                  {!invokeUpdate && (
                    <Button
                      id={"submit"}
                      color="success"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                  {invokeUpdate && (
                    <>
                      <Button
                        id={"cancel"}
                        color="warning"
                        onClick={cancelUpdate}
                      >
                        Cancel
                      </Button>
                      <Button
                        id={"submit"}
                        color="success"
                        onClick={() => handleUpdate(invokeUpdate)}
                      >
                        Update
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </FormGroup>
            </Form>
          </Col>
          <Col className={"pt-4 pt-lg-0"} lg={9} md={12}>
            <Row>
              {mainError && (
                <Alert
                  key={"danger"}
                  color={"danger"}
                  toggle={() => setMainError("")}
                >
                  {mainError}
                </Alert>
              )}
              {mainMessage && (
                <Alert
                  key={"success"}
                  color={"success"}
                  toggle={() => setMainMessage("")}
                >
                  {mainMessage}
                </Alert>
              )}
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Domain</th>
                    <th>Address</th>
                    <th width={128}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((company) => {
                    return (
                      <tr key={company._id}>
                        <th scope="row">{company.name}</th>
                        <td>{company.email}</td>
                        <td>{company.domain}</td>
                        <td>{company.address}</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              id={"update"}
                              color={""}
                              size={"sm"}
                              onClick={() => setupUpdate(company._id)}
                            >
                              <span className="icon sm">{icon("modify")}</span>
                            </Button>
                            <Tooltip
                              isOpen={updateTooltipOpen}
                              target="update"
                              toggle={() =>
                                setUpdateTooltipOpen(!updateTooltipOpen)
                              }
                            >
                              Modify this record
                            </Tooltip>
                            <Button
                              id={"delete"}
                              color={""}
                              size={"sm"}
                              onClick={() => handleDelete(company._id)}
                            >
                              <span className="icon sm">{icon("trash")}</span>
                            </Button>
                            <Tooltip
                              isOpen={deleteTooltipOpen}
                              target="delete"
                              toggle={() =>
                                setDeleteTooltipOpen(!deleteTooltipOpen)
                              }
                            >
                              Delete this record
                            </Tooltip>
                          </ButtonGroup>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      </Container>
    </Globals>
  );
};

export default Companies;
